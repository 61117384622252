const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/privacy-policy"],
    exact: true,
    component: "Policy",
  },
  {
    path: ["/", "/terms-of-service"],
    exact: true,
    component: "Policy",
  }
];

export default routes;
